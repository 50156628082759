import {loadLocaleMessages, setI18nLanguage} from "../i18n";

const load = () => {
	const data = localStorage.getItem("language");
	if (!data) { return emptyData(); }
	return JSON.parse(data);
};

const emptyData = () => ({
	platform: "en_US",
	courseAsPlatform: true,
});

const supportedLanguages = {
	"en": "en_US",
	"en-us": "en_US",
	"en_US": "en_US",
	"es": "es_ES",
	"es_ES": "es_ES",
	"es-es": "es_ES",
	"es_XX": "es_ES",
	"es-xx": "es_ES",
	"es_LA": "es_LA",
	"es-la": "es_LA",
	"ca": "ca_ES",
	"ca_ES": "ca_ES",
	"ca-es": "ca_ES",
	"ro": "ro_RO",
	"ro_RO": "ro_RO",
	"ro-ro": "ro_RO",
	"pt-pt": "pt_PT",
	"pt_PT": "pt_PT",
	"el": "el_GR",
	"gr": "el_GR",
	"el_GR": "el_GR",
	"el-gr": "el_GR",
};

export default class Language {
	constructor(defaultLocale = "en") {
		if (Language._instance) {
			return Language._instance;
		}
		this.data = load();
		this.defaultLocale = defaultLocale;
		Language._instance = this;
	}

	updateI18n(i18n) {
		this.i18n = i18n;
	}

	updateLocale({course_locale, platform_locale}) {
		this.course = course_locale;
		this.platform = platform_locale;
		this.courseAsPlatform = this.course === this.platform;
	}

	_isSupportedLanguage(value) {
		return Object.keys(supportedLanguages).includes(value);
	}

	_toSupportedLanguage(value) {
		return supportedLanguages[value];
	}

	set courseAndPlatform(value) {
		if (!this._isSupportedLanguage(value)) {
			return;
		}

		const language = this._toSupportedLanguage(value);
		this.platform = language;
		this.course = language;
		this.courseAsPlatform = true;
	}

	set platform(value) {
		if (!this._isSupportedLanguage(value)) {
			return;
		}

		this.data.platform = this._toSupportedLanguage(value);

		this._save();
		this._updatePlatform(this.platformLanguage).then(() => {});
	}

	set course(value) {
		if (!this._isSupportedLanguage(value)) {
			return;
		}

		this.data.course = this._toSupportedLanguage(value);
		this.courseAsPlatform = false;
		this._save();
	}

	set courseAsPlatform(value) {
		this.data.courseAsPlatform = value;
		this._save();
	}

	get platform() {
		return this.data.platform || this.defaultLocale;
	}

	get platformLanguage() {
		return this.platform.split("_")[0];
	}

	get courseAsPlatform() {
		if (typeof this.data.courseAsPlatform === "undefined") {
			return true;
		}
		return this.data.courseAsPlatform;
	}

	get course() {
		return this.courseAsPlatform ? this.platform : this.data.course;
	}

	_save() {
		localStorage.setItem("language", JSON.stringify(this.data));
	}

	async _updatePlatform(locale) {
		await loadLocaleMessages(this.i18n, locale);
		// set i18n language
		setI18nLanguage(this.i18n, locale);
		window.dayjs.locale(locale);
	}

}